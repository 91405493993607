import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import "normalize.css"
import scrollTo from 'gatsby-plugin-smoothscroll';
import {
  listItemTitle,
  upArrow,
  listItemCursor,
  panel,
  itemContainer,
} from '../components/layout.module.css'
import './index.scss'
import Typewriter from 'typewriter-effect';
import GraphemeSplitter from "grapheme-splitter";


const IndexPage = ({ data }) => {
  const stringSplitter = string => {
    const splitter = new GraphemeSplitter();
    return splitter.splitGraphemes(string);
  };
  return (
    <Layout pageTitle="Blog">
      {
        data.allMdx.nodes.map((node) => (
         
                    <article className={panel} key={node.id} >
                <Link className={itemContainer} to={`/blog/${node.slug}`}>
                <Typewriter
                options={{skipAddStyles:true, 
                  wrapperClassName:listItemTitle,
                  cursorClassName: listItemCursor, 
                  delay: 20,
                  stringSplitter}}
  onInit={(typewriter) => {
    typewriter.typeString(node.frontmatter.title)
      .start();
  }}
/>
                    {/* <div className={listItemTitle}>{node.frontmatter.title}</div> */}
                {/* <div className={listItemDate}>{node.frontmatter.date}</div> */}
                {/* <div className={listItemExcerpt} >{node.excerpt}</div> */}
                {/* <p className={listItemTags}>
                {node.frontmatter.tags.map(tag => (	<span class="projcard-tag">{tag}</span>))}
                </p> */}
                </Link>

              </article>

        ))
      }
      <button className={upArrow} 
        data-sal="fade"
        data-sal-delay="300"
        data-sal-duration="500"
        data-sal-easing="ease" onClick={() => scrollTo('#blog')}>↑</button>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          tags
        }
        parent {
          ... on File {
            modifiedTime(formatString: "MMMM D, YYYY")
          }
        }
        id
        slug
        excerpt
      }
    }
  }
`

export default IndexPage